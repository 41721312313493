import { useEffect, useMemo, useState } from 'react';
import MenuIcon from '../../components/icons/menu';
import { t } from '../../stores/i18n.ts';
import { useLockScroll } from '../../utils';
import { Context } from '../../utils/context.ts';
import WithApp from '../../utils/withApp.tsx';
import { useIsLoggedIn, useLogout } from '../../utils/hooks.tsx';

type Props = {
  path: string;
  context: Context;
};

function Component({ context, path }: Props) {
  const [open, setOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState(path);
  const isLoggedIn = useIsLoggedIn(context);
  const logout = useLogout();

  const navItems: Array<{
    key: string;
    url: string;
    onClick?: () => void;
  }> = useMemo(() => {
    const baseItems = [
      { key: 'home', url: '/' },
      { key: 'about', url: '/about' },
      { key: 'contact', url: '/contact' },
    ];

    if (isLoggedIn) {
      return [...baseItems, { key: 'logout', url: '/logout', onClick: logout }];
    }

    return baseItems;
  }, [isLoggedIn, logout]);

  useLockScroll(open);

  useEffect(() => {
    const callback = function () {
      setCurrentPath('');
    };

    document.getElementById('home-link')?.addEventListener('click', callback);

    return () => {
      document.getElementById('home-link')?.removeEventListener('click', callback);
    };
  }, []);

  return (
    <>
      <div className="w-full items-center hidden md:flex">
        {navItems.map(item => {
          const baseClass =
            'text-center rounded-xl py-2 px-4 no-underline hover:bg-slate-200 mx-2 text-[rgba(25,25,27,0.75)] hover:text-[var(--theme-primary)] ';

          if (item.onClick) {
            return (
              <button key={item.key} type="button" onClick={item.onClick} className={baseClass}>
                {t(`nav_${item.key}`, context.locale)}
              </button>
            );
          }
          return (
            <a
              key={item.key}
              onClick={() => {
                setCurrentPath(item.url);
                return setOpen(false);
              }}
              href={item.url}
              className={`${baseClass} ${item.url === currentPath ? 'bg-slate-200' : ''} `}>
              <span>{t(`nav_${item.key}`, context.locale)}</span>
            </a>
          );
        })}
      </div>

      <a href="/download" className="btn-action">
        {t('nav_download', context.locale)}
      </a>

      <span
        className="block cursor-pointer rounded-full bg-white px-5 py-2 hover:bg-slate-200 md:hidden right-4 top-8"
        onClick={() => setOpen(true)}>
        <MenuIcon />
      </span>

      <div
        onClick={() => setOpen(false)}
        className={`${
          open ? 'visible opacity-40' : 'invisible opacity-0'
        } z-40 fixed top-0 left-0 h-screen w-screen bg-black duration-100 transition-all`}
      />
      <div
        className={`z-40 fixed top-0 h-screen w-[240px] bg-white duration-200 ${
          open ? 'right-0 ' : 'right-[-250px] '
        }`}>
        <div className="flex h-full flex-col items-start overflow-auto">
          <div className="flex w-full flex-col items-center space-y-5 px-6 pt-20">
            {navItems.map(item => {
              const matches =
                item.url === currentPath || (item.url === '/' && ['/', ''].includes(currentPath));

              const baseClass =
                'text-center rounded-xl py-2 px-4 no-underline hover:bg-slate-200 mx-2 text-[rgba(25,25,27,0.75)] hover:text-[var(--theme-primary)] ';

              if (item.onClick) {
                return (
                  <button key={item.key} type="button" onClick={item.onClick} className={baseClass}>
                    {t(`nav_${item.key}`, context.locale)}
                  </button>
                );
              }

              return (
                <a
                  key={item.key}
                  onClick={() => {
                    setCurrentPath(item.url);
                    setOpen(false);
                  }}
                  href={item.url}
                  className={`${baseClass} ${matches ? 'bg-slate-200' : ''}
                  `}>
                  <span>{t(`nav_${item.key}`, context.locale)}</span>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default function Navigation({ path, context }: Props & { context: Context }) {
  return (
    <WithApp context={context}>
      <Component context={context} path={path} />
    </WithApp>
  );
}
